@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MadeofScars";
  src: url("./assets/fonts/MadeofScars-Regular.woff2") format("woff2"),
    url("./assets/fonts/MadeofScars-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SK Seren";
  src: url("./assets/fonts/SKSeren-Regular.woff2") format("woff2"),
    url("./assets/fonts/SKSeren-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SK Seren";
  src: url("./assets/fonts/SKSeren-Bold.woff2") format("woff2"),
    url("./assets/fonts/SKSeren-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karlsbader Grotesk";
  src: url("./assets/fonts/KarlsbaderGrotesk-400.woff2") format("woff2"),
    url("./assets/fonts/KarlsbaderGrotesk-400.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karlsbader Grotesk";
  src: url("./assets/fonts/KarlsbaderGrotesk-600.woff2") format("woff2"),
    url("./assets/fonts/KarlsbaderGrotesk-600.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-family: "MadeofScars", sans-serif;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

canvas {
  min-height: 100vh;
}

body {
  background: #000;
}
